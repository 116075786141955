export const userDataReducer = (state, action) => {
	switch (action.type) {
		case 'SET_CART': {
			return {
				...state,
				cartBooks: [...action.payload.books],
				cartTotalAmount: action.payload.totalPrice,
			};
		}

		case 'SET_ADDRESS': {
			return { ...state, addressList: [...action.payload] };
		}

		case 'SET_ORDER': {
			return {
				...state,
				orderDetails: {
					...state.orderDetails,
					...action.payload,
				},
			};
		}

		case 'SET_ORDERS': {
			return {
				...state,
				orders: [...action.payload],
			};
		}

		default:
			return state;
	}
};

export const initialUserData = {
	cartTotalAmount: 0,
	cartBooks: [],
	addressList: [],
	orders: [],
	orderDetails: {
		cartItemsTotal: '',
		cartItemsDiscountTotal: '',
		couponDiscountTotal: '',
		orderAddress: '',
		orderId: '',
	},
};
