export const initialState = {
	allBooksFromApi: [],
	allGenres: [],
	inputSearch: '',
	filters: {
		rating: '',
		genres: [],
		price: [],
		sort: '',
	},
	selectedBook: {},
};

export const dataReducer = (state, action) => {
	switch (action.type) {
		case 'GET_ALL_BOOKS_FROM_API':
			return { ...state, allBooksFromApi: action.payload };

		case 'GET_ALL_GENRES':
			return { ...state, allGenres: action.payload };

		case 'SEARCH':
			return { ...state, inputSearch: action.payload };

		case 'ADD_RATINGS':
			return {
				...state,
				filters: { ...state.filters, rating: action.payload },
			};

		case 'ADD_GENRES': {
			const isGenresPresent = state.filters.genres.find(
				(genre) => genre === action.payload
			);

			return {
				...state,
				filters: {
					...state.filters,
					genres: isGenresPresent
						? state.filters.genres.filter(
								(genres) => genres !== action.payload
						  )
						: [...state.filters.genres, action.payload],
				},
			};
		}

		case 'ADD_SORT':
			return {
				...state,
				filters: { ...state.filters, sort: action.payload },
			};

		case 'ADD_PRICE': {
			const isPricePresent = state.filters.price.find(
				(price) => price.min === action.payload.min
			);
			return {
				...state,
				filters: {
					...state.filters,
					price: isPricePresent
						? state.filters.price.filter(
								(price) => price.min !== action.payload.min
						  )
						: [...state.filters.price, action.payload],
				},
			};
		}

		case 'ADD_GENRES_FROM_HOME':
			return {
				...state,
				filters: { ...state.filters, genres: [action.payload] },
			};

		case 'RESET':
			return { ...state, filters: { ...action.payload } };

		case 'SET_SELECTED_BOOK':
			return { ...state, selectedBook: { ...action.payload } };

		default:
			return state;
	}
};
