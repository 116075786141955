import axios from 'axios';
import { backAPI } from '../constant/constants';

const userAuthLogin = backAPI.userAuthLogin;

const signup = async (access_token) =>
	await axios.post(userAuthLogin, { access_token });

//await axios.post(SingupURL, { credential: access_token });

/*const getProfile = async (email) =>
	await axios.post(ProfileURL, { email }, { headers: auth_header() });
*/
const authService = {
	signup,
	//getProfile,
};

export default authService;
