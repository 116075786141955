import React, { useEffect } from 'react';
import './Login.css';
// import { Link } from 'react-router-dom';
// import { BsEyeSlash } from 'react-icons/bs';
// import { BsEye } from 'react-icons/bs';
// import { useState } from 'react';
import { useAuth } from '../../../contexts/AuthProvider.js'; //contexts/AuthProvider.jsx';
import { useData } from '../../../contexts/DataProvider.js';
import { useNavigate } from 'react-router-dom';
import { paths, words } from '../../../constant/constants';
//import GoogleIcon from '../../../SVG/google.svg';

const Login = () => {
	const navigate = useNavigate();
	const { loading } = useData();
	const { auth, error, loginHandler } = useAuth();

	useEffect(() => {
		if (auth.isAuth) {
			navigate(paths.profilePath);
		}
	}, [auth, navigate]);

	return (
		!loading && (
			<div className='login-container'>
				<h2>{words.login}</h2>
				<form onSubmit={loginHandler} className='login-body'>
					{error && <span className='error'>{error}</span>}
					<div className='login-btn-container '>
						<button type='submit'>
							<img
								src={'/assets/icons/google.svg'}
								alt='google logo'
								style={{ width: '20px', marginRight: '20px' }}
							/>
							<span>{words.login}</span>
						</button>
					</div>
				</form>
			</div>
		)
	);
};
export default Login;
