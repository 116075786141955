import axios from 'axios';
import { backAPI } from '../constant/constants';
const connectionCheck = backAPI.connectionCheck;
const check = async () => await axios.get(connectionCheck);

const connectionService = {
	check,
};

export default connectionService;
