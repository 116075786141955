import React, { Fragment } from 'react';
import { Footer } from '../../components/Footer/Footer';
//import { HeroSection } from './components/HeroSection/HeroSection';
//import { VideosSection } from './components/VideosSection/VideosSection';
import { HeroVideo } from './components/HeroVideo/HeroVideo';
import { useData } from '../../contexts/DataProvider';
import HomeCarousles from './components/HomeCarousles/HomeCarousles';
import './Home.css';
export const Home = () => {
	const { loading } = useData();
	return (
		!loading && (
			<div className='home-page'>
				<div className='hero'>
					<HeroVideo />
					{/* <div className='image-margin-bottom'></div> */}
					{/* <HeroSection />
					<VideosSection /> */}
					<HomeCarousles />
					<Footer />
				</div>
			</div>
		)
	);
};
