import './ProductDescription.css';
import React from 'react';
import { useUserData } from '../../../../contexts/UserDataProvider';
import { paths, words } from '../../../../constant/constants';
import { useNavigate } from 'react-router-dom';

export const ProductDescription = ({ selectedBook }) => {
	const navigate = useNavigate();
	const { addToCartHandler, isProductInCart, cartLoading } = useUserData();

	return (
		<div className='product-details-description'>
			<h1 className='product-name main_text_color '>
				{selectedBook?.title}
			</h1>

			<div className='product-price-container'>
				{/* <span className='product-original-price'>
					${selectedBook?.price}{' '}
				</span> */}
				<span className='gender-container'>
					{/* ${selectedBook?.price} */}
					<span>{`${words.authors}: `}</span> {selectedBook?.authors}
				</span>
			</div>

			<p className='description-container'>
				<span>{words.description}</span> : {selectedBook?.description}
			</p>

			<span className='gender-container'>
				<span>{`${words.genre}: `}</span>
				{selectedBook?.genre}
				{/* {getGenreName(selectedBook?.genre)} */}
			</span>
			{/* <p className='size-container'>
				<span>Pages</span>: {selectedBook?.pagesCount}
			</p> */}

			<div className='tags'>
				{!selectedBook?.is_stock && (
					<span className='out-of-stock warning_text'>
						{selectedBook?.is_stock
							? 'In Stock'
							: words.bookNotAvailable}
					</span>
				)}
				{/*selectedBook?.trending && (
					<span className='trending'>
						{selectedBook?.trending ? 'Trending' : ''}
					</span>
				)*/}
			</div>
			<div className='product-card-buttons-container'>
				{selectedBook?.is_stock && (
					<button
						disabled={cartLoading || !selectedBook?.is_stock}
						onClick={() =>
							!isProductInCart(selectedBook)
								? addToCartHandler(selectedBook)
								: navigate(paths.cartPath)
						}
						className={
							selectedBook?.is_stock
								? 'add-to-cart-btn main_btn_bk'
								: 'add-to-cart-btn disabled-btn'
						}
					>
						{!isProductInCart(selectedBook)
							? words.addToCart
							: words.goToCart}
					</button>
				)}
			</div>
		</div>
	);
};
