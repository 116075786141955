import React from 'react';
import { SyncLoader } from 'react-spinners';

export const Loader = ({ loading }) => {
	const override = {
		position: 'absolute',
		top: '50vh',
		left: '50vw',
	};
	return (
		<SyncLoader cssOverride={override} loading={loading} color='black' />
	);
};
