import axios from 'axios';
import { backAPI } from '../constant/constants';

const userGetOrders = backAPI.userGetOrders;
const userAddOrder = backAPI.userAddOrder;
const userCancelOrder = backAPI.userCancelOrder;

const auth_header = () => ({
	authorization: 'Bearer ' + window.localStorage.getItem('token'),
});

const getOrderService = async () => {
	return await axios.get(userGetOrders, {
		//return await axios.get(adminGetArchivedOrders, {
		headers: auth_header(),
	});
};

const addOrderService = async (addressId) => {
	return await axios.post(
		userAddOrder,
		{ addressId },
		{
			headers: auth_header(),
		}
	);
};

const cancelOrderService = async (orderId) =>
	//return await axios.delete(URL, {
	await axios.post(
		userCancelOrder,
		{ orderId, newStatus: 'canceled' },
		{ headers: auth_header() }
	);

const orderService = {
	getOrderService,
	addOrderService,
	cancelOrderService,
};

export default orderService;
