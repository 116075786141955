import React from 'react';
import { MdDelete } from 'react-icons/md';

import './CartListing.css';

import { useUserData } from '../../../../contexts/UserDataProvider';
import { Link } from 'react-router-dom';
import { paths, words } from '../../../../constant/constants';

export const CartListing = () => {
	const {
		userDataState,

		removeFromCartHandler,
	} = useUserData();

	return (
		<div className='cart-products-container'>
			{userDataState.cartBooks.map((book) => (
				<div className='product-card' key={book._id}>
					<Link to={`${paths.bookDetailsPath}${book._id}`}>
						<div className='product-card-image'>
							<img src={book.imageUrl} alt={book.title} />
						</div>
					</Link>
					<div className='product-description'>
						<h3>{book.title}</h3>
					</div>
					<div className='button-section'>
						<div className='count-btn-container'>
							<button
								className=' warning_bk'
								onClick={() => removeFromCartHandler(book)}
							>
								<MdDelete size={25} />
								{words.removeFromCart}
							</button>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};
