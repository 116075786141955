import './OrderSummary.css';
import React from 'react';
import { CartBooksSummary } from '../CartProductsSummary/CartProductsSummary';
import { DeliveryAddress } from '../DeliveryAddress/DeliveryAddress';
import { useUserData } from '../../../../contexts/UserDataProvider';
import { words } from '../../../../constant/constants';

export const OrderSummary = () => {
	const { userDataState } = useUserData();
	return (
		<div className='order-details-container'>
			<CartBooksSummary />
			{userDataState.orderDetails.orderAddress ? (
				<DeliveryAddress />
			) : (
				<div className='no-address warning_text'>
					{words.askingAddress}
				</div>
			)}
		</div>
	);
};
