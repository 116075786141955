import axios from 'axios';
import { backAPI } from '../constant/constants';

const genresApi = backAPI.generalAllGenres; //'https://glare.cu.ma/book/genre/view.php';
const booksApi = backAPI.generalAllBooks; //'https://glare.cu.ma/book/books/book.php';

const getAllGenres = async () => await axios.get(genresApi);

const getAllBooks = async () => await axios.get(booksApi);

const dataServices = {
	getAllGenres,
	getAllBooks,
};

export default dataServices;
