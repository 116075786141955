import React from 'react';
import './Cart.css';

import { CartListing } from './components/CartListing/CartListing';
import { CartAmountSummary } from './components/CartAmountSummary/CartAmountSummary';
import { useUserData } from '../../contexts/UserDataProvider';
import { useData } from '../../contexts/DataProvider';
import { words } from '../../constant/constants';
import NoItemInCart from './components/NoItemInCart';

const Cart = () => {
	const { userDataState } = useUserData();
	const { loading } = useData();

	return (
		!loading &&
		(userDataState.cartBooks.length ? (
			<div>
				<h1 className='page-heading main_text_color'>{words.cart}</h1>
				<div className='cart-container'>
					<CartListing />
					<div>
						<CartAmountSummary />
					</div>
				</div>
			</div>
		) : (
			<NoItemInCart />
		))
	);
};
export default Cart;
