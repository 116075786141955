import {
	createContext,
	useContext,
	useEffect,
	useReducer,
	useState,
} from 'react';

import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { userDataReducer, initialUserData } from '../reducer/userDataReducer';
import { notificationMessages, paths, words } from '../constant/constants';
import { useAuth } from './AuthProvider';

import orderService from '../services/orderServices';
import cartServices from '../services/cartServices';

const UserDataContext = createContext();

export function UserProvider({ children }) {
	const [cartLoading, setCartLoading] = useState(false);
	//const [error, setError] = useState('');
	const [userDataState, dispatch] = useReducer(
		userDataReducer,
		initialUserData
	);

	const { auth } = useAuth();

	const navigate = useNavigate();
	const location = useLocation();

	const addToCartHandler = async (book) => {
		if (auth.isAuth) {
			if (!book.is_stock) {
				toast.error(`${book.title} ${words.bookNotAvailable}.`);
			} else {
				if (!isProductInCart(book)) {
					try {
						setCartLoading(true);
						//setError('');
						const response = await cartServices.addToCart(book._id);

						if (response.status === 201) {
							if (response.data.status === 'success') {
								let cart = response.data.data;
								cart.books =
									cart.countBook > 0
										? JSON.parse(cart.books)
										: [];
								dispatch({
									type: 'SET_CART',
									payload: {
										books: cart.books,
										totalPrice: cart.totalPrice,
									},
								});
								//setCartLoading(false);
								//toast.success(response.data.message);
								toast.success(
									notificationMessages.bookAddedToCart
								);
							}
						} else {
							toast.error(
								"can't add to cart; " + response.data.message
							);
						}
					} catch (error) {
						//setCartLoading(false);
						//console.error(error);
					} finally {
						setCartLoading(false);
					}
				} else {
					toast.error(
						`${book.title} ${notificationMessages.bookAlreadyInCart}!`
					);
					navigate(paths.cartPath);
				}
			}
		} else {
			toast(`${notificationMessages.loginRequiredMsg}!`);
			navigate('/login', { state: { from: location } });
		}
	};
	const getcartBooks = async () => {
		if (auth.isAuth) {
			try {
				//setCartLoading(true);
				//setError('');
				const response = await cartServices.getCart();
				//console.log(response);
				if (response.status === 200) {
					if (response.data.status === 'success') {
						let cart = response.data.data;
						//console.log(JSON.parse(cart.books));
						//console.log(cart);
						cart.books =
							cart.countBook > 0 ? JSON.parse(cart.books) : [];
						//console.log(cart.books);
						dispatch({
							type: 'SET_CART',
							payload: {
								books: cart.books,
								totalPrice: cart.totalPrice,
							},
						});
						//setCartLoading(false);
					} else {
						// toast.error(
						// 	"can't return the cart; " + response.data.message
						// );
					}
				}
			} catch (error) {
				//console.log(error);
				//setCartLoading(false);
			} finally {
				setCartLoading(false);
			}
		}
	};

	const removeFromCartHandler = async (book) => {
		try {
			setCartLoading(true);
			//setError('');
			const response = await cartServices.removeFromCart(book._id);
			if (response.status === 200) {
				if (response.data.status === 'success') {
					let cart = response.data.data;
					cart.books =
						cart.countBook > 0 ? JSON.parse(cart.books) : [];
					dispatch({
						type: 'SET_CART',
						payload: {
							books: cart.books,
							totalPrice: cart.totalPrice,
						},
					});
					//setCartLoading(false);
					//toast.success(response.data.message);
					toast.success(notificationMessages.bookRemovedFromCart);
				} else {
					toast.error(
						"can't remove from the cart; " + response.data.message
					);
				}
			}
		} catch (error) {
			//setCartLoading(false);
		} finally {
			setCartLoading(false);
		}
	};

	const clearCartHandler = async () => {
		try {
			//const response = await cartServices.clearCart();
			//if (response.status === 200) {
			dispatch({
				type: 'SET_CART',
				payload: {
					books: [],
					totalPrice: 0,
				},
			});
			//}
			/*dispatch({
				type: 'SET_CART',
				payload: {
					books: [],
					totalPrice: 0,
				},
			});*/
		} catch (error) {
			console.error(error);
		}
	};

	const isProductInCart = (book) => {
		const found = userDataState.cartBooks.find(
			(item) => item._id === book._id
		);
		return found ? true : false;
	};

	const totalDiscountedPrice = userDataState.cartBooks?.reduce(
		(acc, curr) => acc + curr.discounted_price * curr.qty,
		0
	);

	const totalOriginalPrice = userDataState.cartBooks?.reduce(
		(acc, curr) => acc + curr.original_price * curr.qty,
		0
	);

	const discountPercent = () => {
		const totalPrice = userDataState?.cartBooks?.reduce(
			(acc, curr) => ({
				...acc,
				original: acc.original + curr.original_price,
				discount: acc.discount + curr.discounted_price,
			}),
			{ original: 0, discount: 0 }
		);

		const totalDiscount =
			(totalPrice.original - totalPrice.discount) / totalPrice.original;

		return totalDiscount?.toFixed(2) * 100;
	};

	////////////  Order
	const addOrderHandler = async () => {
		if (auth.isAuth) {
			if (!userDataState.cartBooks.length > 0) {
				toast.error(`${notificationMessages.cartEmptyMsg}!`);
			} else {
				try {
					setCartLoading(true);
					//setError('');
					const response = await orderService.addOrderService(
						userDataState.orderDetails.orderAddress._id
					);
					if (response.status === 201 || response.status === 200) {
						if (response.data.status === 'success') {
							let orders = response.data.data;
							orders = orders.map((o) => ({
								...o,
								booksInfo: JSON.parse(o.booksInfo),
								user: JSON.parse(o.user),
								address: JSON.parse(o.address),
							}));
							//setCartLoading(false);
							dispatch({
								type: 'SET_ORDERS',
								payload: orders,
							});
							clearCartHandler();
							toast.success(
								`${notificationMessages.orderCreateMsg}!`
							);
							setCartLoading(false);
							return true;
						} else {
							setCartLoading(false);
							toast.error(`${notificationMessages.orderFailed}!`);
							//toast.error(`${response.data.message}!`);
							return false;
						}
					} else {
						setCartLoading(false);
						toast.error(`${notificationMessages.orderFailed}!`);
						toast.error(`${response.data.message}!`);
						return false;
					}
				} catch (error) {
					setCartLoading(false);
					toast.error(`${notificationMessages.orderFailed}!`);
					console.error(error);
					return false;
				}
			}
		} else {
			toast(`${notificationMessages.loginRequiredMsg}!`);
			navigate('/login', { state: { from: location } });
		}
	};
	const getUserOrdersList = async () => {
		if (auth.isAuth) {
			try {
				const response = await orderService.getOrderService();
				if (response.status === 200) {
					if (response.data.status === 'success') {
						let orders = response.data.data;
						orders = orders.map((o) => ({
							...o,
							booksInfo: JSON.parse(o.booksInfo),
							user: JSON.parse(o.user),
							address: JSON.parse(o.address),
						}));
						dispatch({
							type: 'SET_ORDERS',
							payload: orders,
						});
						//toast.success('order success');
					} else {
						// toast.error(
						// 	"can't return the order; " + response.data.message
						// );
					}
				}
			} catch (error) {
				//setCartLoading(false);
				console.error(error);
				//toast.error('order failed');
			}
		}
	};
	const cancelOrderHandler = async (orderId) => {
		if (auth.isAuth) {
			try {
				setCartLoading(true);
				//setError('');
				const response = await orderService.cancelOrderService(orderId);
				if (response.status === 200) {
					if (response.data.status === 'success') {
						let orders = response.data.data;
						orders = orders.map((o) => ({
							...o,
							booksInfo: JSON.parse(o.booksInfo),
							user: JSON.parse(o.user),
							address: JSON.parse(o.address),
						}));
						setCartLoading(false);
						dispatch({
							type: 'SET_ORDERS',
							payload: orders,
						});
						toast.success(
							`${notificationMessages.orderCancelMsg}!`
						);
						setCartLoading(false);
						return true;
					} else {
						setCartLoading(false);
						toast.error(
							`${notificationMessages.orderCancelFailed}!`
						);
						toast.error(`${response.data.message}!`);
						return false;
					}
				} else {
					setCartLoading(false);
					toast.error(`${notificationMessages.orderCancelFailed}!`);
					toast.error(`${response.data.message}!`);
					return false;
				}
			} catch (error) {
				setCartLoading(false);
				toast.error(`${notificationMessages.orderCancelFailed}!`);
				console.error(error);
				return false;
			}
		} else {
			toast(`${notificationMessages.loginRequiredMsg}!`);
			navigate('/login', { state: { from: location } });
		}
	};

	useEffect(() => {
		if (auth.isAuth) {
			setCartLoading(true);
			getcartBooks();
			getUserOrdersList();
			setCartLoading(false);
		}
	}, [auth]);

	return (
		<UserDataContext.Provider
			value={{
				userDataState,
				dispatch,
				cartLoading,
				addToCartHandler,
				removeFromCartHandler,
				clearCartHandler,
				isProductInCart,

				totalDiscountedPrice,
				totalOriginalPrice,
				discountPercent,
				initialUserData,
				addOrderHandler,
				cancelOrderHandler,
			}}
		>
			{children}
		</UserDataContext.Provider>
	);
}

export const useUserData = () => useContext(UserDataContext);
