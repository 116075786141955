import React from 'react';
import { useAuth } from '../../../contexts/AuthProvider';
import Logout from '../../auth/Logout/Logout.jsx'; //"../../Auth/Logout/Logout";
import './Profile.css';
import { words } from '../../../constant/constants';

const Profile = () => {
	const { auth } = useAuth();

	return (
		<div className='profile-container'>
			<div className='profile-details'>
				<div className='name'>
					<span>{words.fullNameProfile}: </span>
					<span>
						{auth.firstName} {auth.lastName}
					</span>
				</div>

				<div className='email'>
					<span>{words.emailProfile}: </span>
					<span> {auth.email} </span>
				</div>
			</div>
			<Logout />
		</div>
	);
};

export default Profile;
