import {
	createContext,
	useContext,
	useEffect,
	useReducer,
	useState,
} from 'react';

import { dataReducer, initialState } from '../reducer/dataReducer';
import dataServices from '../services/dataServices';

const DataContext = createContext();

export function DataProvider({ children }) {
	const [state, dispatch] = useReducer(dataReducer, initialState);
	const [loading, setLoading] = useState(false);
	//const [error, setError] = useState(false);

	const getBooks = async () => {
		try {
			//setError(false);
			const response = await dataServices.getAllBooks();
			if (response.request.status === 200) {
				if (response.data.status === 'success') {
					const books = response.data.data.map((book) => ({
						...book,
						is_stock: book.availableCount > 0 ? true : false,
					}));
					dispatch({
						type: 'GET_ALL_BOOKS_FROM_API',
						payload: books,
					});
					//toast.success('get books success');
				} else {
					// toast.error(
					// 	"can't return the books; " + response.data.message
					// );
					// toast.error('get books failed');
				}
			}
		} catch (error) {
			//setError(true);
			//console.error(error);
		}
	};
	//Genre
	const getGenres = async () => {
		try {
			const response = await dataServices.getAllGenres();
			if (response.request.status === 200) {
				dispatch({
					type: 'GET_ALL_GENRES',
					payload: response.data.data, //.genres,
				});
			}
		} catch (error) {
			//console.error('getGenres', error);
		}
	};

	/*const getBookById = async (bookId) => {
		const response = await dataServices.getBookById(bookId);
		const book = response.data.book;
		dispatch({ type: 'SET_SELECTED_BOOK', payload: book });
	};*/

	useEffect(() => {
		const load = async () => {
			setLoading(true);
			getBooks();
			getGenres();
			setLoading(false);
		};
		load();
	}, []);

	return (
		<DataContext.Provider value={{ state, dispatch, loading }}>
			{children}
		</DataContext.Provider>
	);
}

export const useData = () => useContext(DataContext);
