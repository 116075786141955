import './CartProductsSummary.css';

import React from 'react';
import { useUserData } from '../../../../contexts/UserDataProvider';
import { words } from '../../../../constant/constants';

export const CartBooksSummary = () => {
	const { userDataState } = useUserData();
	return (
		<div className='product-details-container'>
			<h1 className='main_text_color'>{words.inYourBag}</h1>
			<div className='ordered-products-container'>
				{userDataState.cartBooks?.map(
					({ _id: id, imageUrl: img, title: name }) => (
						<div key={id} className='ordered-product-card'>
							<img src={img} alt={name} />
							<span>
								<span>{name}</span>
							</span>
						</div>
					)
				)}
			</div>
		</div>
	);
};
