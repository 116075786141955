import React from 'react';
//import { Loader } from '../components/Loader/Loader';
import { Home } from '../pages/Home/Home';

import { Navigate, Route, Routes } from 'react-router-dom';
import Login from '../pages/auth/Login/Login.jsx';
import ProductListing from '../pages/ProductListing/ProductListing';
import ProductDetails from '../pages/ProductDetails/ProductDetails';
import { RequiresAuth } from '../components/requires-auth/RequiresAuth';
//import Logout from '../pages/auth/Logout/Logout.jsx';
import Cart from '../pages/Cart/Cart';
import Checkout from '../pages/Checkout/Checkout';
import UserProfile from '../pages/UserProfile/UserProfile';
import Profile from '../pages/UserProfile/Profile/Profile';
import Addresses from '../pages/UserProfile/Addresses/Addresses';
import Orders from '../pages/UserProfile/Orders/Orders';
import PageNotFound from '../pages/PageNotFound/PageNotFound';
import { paths } from '../constant/constants';
// const Login = lazy(() => import('../pages/auth/Login/Login.jsx'));
// const ProductListing = lazy(() =>
// 	import('../pages/ProductListing/ProductListing')
// );
// const ProductDetails = lazy(() =>
// 	import('../pages/ProductDetails/ProductDetails')
// );
//const Logout = lazy(() => import('../pages/auth/Logout/Logout.jsx'));
// const Cart = lazy(() => import('../pages/Cart/Cart'));
// const Checkout = lazy(() => import('../pages/Checkout/Checkout'));
// const UserProfile = lazy(() => import('../pages/UserProfile/UserProfile'));
// const Profile = lazy(() => import('../pages/UserProfile/Profile/Profile'));
//const Addresses = lazy(() =>
// 	import('../pages/UserProfile/Addresses/Addresses')
// );
//const PageNotFound = lazy(() => import('../pages/PageNotFound/PageNotFound'));
//const Orders = lazy(() => import('../pages/UserProfile/Orders/Orders'));

export const NavRoutes = () => {
	return (
		<Routes>
			<Route path={paths.indexPath} element={<Home />} />

			<Route
				path={paths.signupPath}
				element={<Navigate to={paths.loginPath} replace />}
			/>
			<Route path={paths.loginPath} element={<Login />} />
			{/* <Route
					path={paths.logoutPath}
					element={
						<Suspense
							fallback={
								<div>
									<Loader />
								</div>
							}
						>
							<Logout />
						</Suspense>
					}
				/> */}

			{/******/}
			<Route path={paths.booksListPath} element={<ProductListing />} />
			<Route
				path={paths.bookDetailsPath + ':bookId'}
				element={<ProductDetails />}
			/>

			<Route
				path={paths.cartPath}
				element={
					<RequiresAuth>
						<Cart />
					</RequiresAuth>
				}
			/>
			<Route
				path={paths.checkoutPath}
				element={
					<RequiresAuth>
						<Checkout />
					</RequiresAuth>
				}
			/>

			<Route path={paths.profilePath} element={<UserProfile />}>
				<Route
					path={paths.profileDetailsPath}
					element={
						<RequiresAuth>
							<Profile />
						</RequiresAuth>
					}
				/>
				<Route
					path={paths.profileOrdersPath}
					element={
						<RequiresAuth>
							<Orders />
						</RequiresAuth>
					}
				/>
				<Route
					path={paths.profileAddressesPath}
					element={
						<RequiresAuth>
							<Addresses />
						</RequiresAuth>
					}
				/>
			</Route>

			<Route path={paths.notFoundPath} element={<PageNotFound />} />
		</Routes>
	);
};
