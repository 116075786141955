import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import App0 from './App0';

// Call make Server
//makeServer();
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	//<React.StrictMode>
	<Router>
		<App0 />
	</Router>
	//</React.StrictMode>
);
