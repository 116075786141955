import React from 'react';
import { SyncLoader } from 'react-spinners';
import { useData } from '../../contexts/DataProvider';
import { useUserData } from '../../contexts/UserDataProvider';

export const Loader = () => {
	const { loading } = useData();
	const { cartLoading } = useUserData();
	const override = {
		position: 'absolute',
		top: '50vh',
		left: '50vw',
	};
	return (
		<SyncLoader
			cssOverride={override}
			loading={loading || cartLoading}
			color='black'
		/>
	);
};
