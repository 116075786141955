import axios from 'axios';
import { backAPI } from '../constant/constants';

const userGetCart = backAPI.userGetCart;
const userAddToCart = backAPI.userAddToCart;
const userRemoveFromCart = backAPI.userRemoveFromCart;

const auth_header = () => ({
	authorization: 'Bearer ' + window.localStorage.getItem('token'),
});

const getCart = async () =>
	await axios.get(userGetCart, {
		headers: auth_header(),
	});

const addToCart = async (bookId) => {
	return await axios.post(
		userAddToCart,
		{ bookId },
		{
			headers: auth_header(),
		}
	);
};

const removeFromCart = async (bookId) => {
	return await axios.post(
		userRemoveFromCart,
		{ bookId },
		{ headers: auth_header() }
	);
};

const cartServices = {
	getCart,
	addToCart,
	removeFromCart,
};

export default cartServices;
