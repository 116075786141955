const auth = {
	authToken: 'authToken',
	authUser: 'authUser',
};

// const base = 'http://localhost:27018/api';
// const baseUser = `${base}/user`;
// const baseAdmin = `${base}/admin`;

const baseUrl = 'https://alkotobalbakia.com/book';

const userCartUrl = `${baseUrl}/cart`;
const userOrderUrl = `${baseUrl}/order`;
const userAddressUrl = `${baseUrl}/address`;
const backAPI = {
	//**		General		**//
	generalAllBooks: `${baseUrl}/books/book.php`,
	generalAllGenres: `${baseUrl}/genre/view.php`,
	connectionCheck: `${baseUrl}/check.php`,

	//**		USER		**//
	//user
	userAuthLogin: `${baseUrl}/auth/googleLogin.php`,
	//cart
	userGetCart: `${userCartUrl}/view.php`,
	userAddToCart: `${userCartUrl}/add.php`,
	userRemoveFromCart: `${userCartUrl}/delete.php`,
	//order
	userGetOrders: `${userOrderUrl}/view.php`,
	userAddOrder: `${userOrderUrl}/add.php`,
	userCancelOrder: `${userOrderUrl}/cancel.php`,
	//userRemoveFromCart: `${userCartUrl}/delete.php`,
	// address
	userGetAddress: `${userAddressUrl}/view.php`,
	userAddAddress: `${userAddressUrl}/add.php`,
	userUpdateAddress: `${userAddressUrl}/update.php`, //not done
	userRemoveAddress: `${userAddressUrl}/delete.php`, // not done
};

// const dbConstants = {
// 	/*		USER		*/
// 	// user auth
// 	userAuth: `${baseUser}/auth`,
// 	// user profile
// 	userProfile: `${baseUser}/profile`,
// 	// user address
// 	userAddress: `${baseUser}/address`,
// 	// user cart
// 	userCart: `${baseUser}/cart`,
// 	// user order
// 	userOrder: `${baseUser}/order`,
// 	// user comment
// 	userComment: `${baseUser}/comment`,
// 	// user rate
// 	userRate: `${baseUser}/rate`,

// 	/*		GENERAL		*/
// 	// genres
// 	genres: `${base}/genres`,
// 	// books
// 	books: `${base}/books`,

// 	// book by Id
// 	bookById: `${base}/bookById`,
// 	// book comments	: bookComments: `${BASE}/book/comments`,

// 	/*		ADMIN		*/
// 	//Admin Base
// 	//baseAdmin: `${baseAdmin}`,
// 	adminBooks: `${baseAdmin}/books`,
// 	adminBookUplaodImage: `${baseAdmin}/bookUpladImage`,
// 	bookAvailable: `${baseAdmin}/bookAvailable`,
// 	adminAuth: `${baseAdmin}/auth`,
// 	adminOrders: `${baseAdmin}/orders`,
// };

// words
const words = {
	// Home
	homeAppName: 'دار الكتب الباقية',
	homeAppst1: '',
	homeAppst2: '',
	shopNowButton: 'استكشف الآن',
	newAddBooks: 'توفر حديثًا',
	//nav
	appName: 'دار الكتب الباقية',
	searchPlaceholder: 'البحث',
	explore: 'المكتبة',
	exploreAdmin: 'الكتب',
	navOrders: 'الطلبات',
	loginNav: 'تسجيل دخول',
	profileNav: 'حسابي',
	//Filter
	filterGenres: 'تصنيفات الكتب',
	//Book list
	genre: 'التصنيف',
	bookNotAvailable: 'غير متوفر حالياً',
	addToCart: 'إضف إلى السلة',
	goToCart: 'مشاهدة السلة',
	description: 'الوصف',
	reviews: 'مراجعات',
	authors: 'المؤلفون',
	noMatchingBooks: 'لا يوجد كتب مطابقة!',
	//Cart
	cart: 'سلة التسوق',
	removeFromCart: 'إزالة',
	price: 'السعر',
	summary: 'ملخص',
	placeOrder: 'اطلب الآن',
	//Checkout
	checkout: '!تأكيد عملية الشراء',
	noItemInCart: '!لم يتم إضافة عناصر في السلة بعد',
	inYourBag: 'السلة',
	askingAddress: 'الرجاء إضافة/اختيار عنوان',
	orderDeliveringTo: 'عنوان الطلب',
	checkoutPlaceOrder: 'تأكيد الطلب',
	//profile
	profilePage: 'معلومات الحساب',
	fullNameProfile: 'الاسم',
	emailProfile: 'البريد الإلكتروني',
	ordersPage: 'الطلبات',
	noOrder: 'لا يوجد طلبات سابقة',
	orderID: 'رقم الطلب',
	orderPaidAmount: 'السعر الإجمالي',
	orderStatus: 'حالة الطلب',
	orderDeliveryAddress: 'عنوان الطلب',
	orderBookTitle: 'عنوان الكتاب',
	orderBookQty: 'عدد النسخ',
	orderBookPrice: 'السعر',
	orderPending: 'معلق',
	orderAccepted: 'مقبول',
	orderRejected: 'مرفوض',
	orderCanceled: 'مُلغَى',
	orderArchive: 'أرشفة الطلب',
	orderUnarchive: 'إلغاء الأرشفة',
	orderCreationDate: 'تاريخ تقديم الطلب',
	orderHandleDate: 'تاريخ معالجة الطلب',

	addressesPage: 'العناوين',
	noAddress: 'لا يوجد عناوين',
	newAddress: 'إضافة عنوان',
	fullAddress: 'العنوان',
	addressPhone: 'رقم الجوال',
	addressEdit: 'تعديل',
	addressDelete: 'حذف',
	//Address Form
	addressForm: 'استمارة العنوان',
	addressFormName: 'اسم العنوان',
	addressFormStreet: 'الشارع',
	addressFormCity: 'المدينة',
	addressFormState: 'المحافظة',
	addressFormCountry: 'البلد',
	addressFormPhone: 'رقم الهاتف',
	addressFormSave: 'حفظ',
	addressFormCancel: 'إلغاء',

	// Order table header
	orderExportAll: 'تصدير جميع الطلبات',
	orderExportPage: 'تصدير الصفحة الحالية',
	orderExportSelect: 'تصدير الكتب المختارة',
	orderRefresh: 'تحديث',
	// Order table
	orderOrderId: 'رقم الطلب',
	orderCustName: 'اسم الزبون',
	orderCustEmail: 'البريد الالكتروني',
	orderCustAddress: 'عنوان الزبون',
	orderTStatus: 'حالة الطلب',
	orderDate: 'تاريخ الطلب',
	// Order Action
	orderAccept: 'قبول الطلب',
	orderReject: 'رفض الطلب',
	orderCancel: 'إلغاء الطلب',
	// Books table header
	bookNew: 'إضافة كتاب جديد',
	bookExportAll: 'تصدير جميع الكتب',
	bookExportPage: 'تصدير الصفحة الحالية',
	bookExportSelect: 'تصدير الكتب المختارة',
	// Books table
	bookTitle: 'العنوان',
	bookAuthers: 'الكتّاب',
	bookGenre: 'النوع',
	bookAvailable: 'المتاح',
	bookPrice: 'السعر',
	bookDescr: 'الوصف',
	// Book Action
	bookDelete: 'حذف الكتاب',
	// Add Book Form
	AddBookFormHead: 'إضافة كتاب',
	AddBookFormISBN: 'ادخل ISBN',
	AddBookFormTitle: 'ادخل عنوان الكتاب',
	AddBookFormAuthors: 'ادخل الكتّاب',
	AddBookFormGenre: 'اختر نوع الكتاب',
	addBookFormNullGenre: 'الرجاء اختيار نوع الكتاب',
	AddBookFormYear: 'ادخل سنة الكتاب',
	AddBookFormDescr: 'ادخل وصف الكتاب',
	AddBookFormPrice: 'ادخل سعر الكتاب',
	AddBookFormAvailable: 'ادخل عدد النسخ المتوفرة',
	AddBookFormPages: 'ادخل عدد صفحات الكتاب',
	AddBookFormImage: 'اختر غلاف الكتاب',
	AddBookFormSubmit: 'أضف الكتاب',

	//Auth
	login: 'تسجيل الدخول',
	logout: 'تسجيل خروج',
	// Not Found
	//Error404:'',
	PageNotFound: 'الصفحة غير موجودة',
	backHome: 'الصفحة الرئيسية',
	//no connection
	noConnectToBack: 'عذراً، يرجى المحاولة لاحقاً',
};

// Input notifications
const notifications = {
	welcomeMsg: 'Welcome to our online store',
	goToHome: 'Go to home',
	goToStore: 'Go to store',
	viewOrders: 'View your orders',
	notFound: 'Page not found',
	credentialsRequired: 'Please provide credentials',
	emailRequired: 'Please provide your email address',
	passwordRequired: 'Please provide your password',
	passwordsDoNotMatch: 'Passwords do not match',
	usernameRequired: 'Please provide your username',
	bookTitleRequired: 'Please provide book title',
	bookAuthorRequired: 'Please provide book author',
	bookGenresRequired: 'Please provide book genres',
	bookImageRequired: 'Please provide book image URL',
	bookPriceRequired: 'Please provide valid book price',
	bookDescriptionRequired: 'Please provide book description',
	bookDataRequired: 'Please provide book data',
};

const notificationMessages = {
	// Auth
	loginSuccess: 'مرحباً',
	loginRequiredMsg: 'الرجاء تسجيل الدخول اولاً',
	loginFailed: 'فشل تسجيل الدخول',
	logoutSuccessMsg: 'تم تسجيل الخروج',

	// Cart
	bookAddedToCart: 'تم إضافة الكتاب إلى السلة',
	bookRemovedFromCart: 'تم إزالة الكتاب من السلة',
	bookAlreadyInCart: 'موجود في السلة مسبقاَ',
	cartEmptyMsg: 'عذراً، السلة فارغة',
	cartUpdatedMsg: 'Cart updated',
	// Orders
	orderCreateMsg: 'تم تسجيل الطلب، سيتم التواصل معك قريباً',
	orderCancelMsg: 'تم إلغاء الطلب',
	orderApprovedMsg: 'Order approved',
	orderNotFoundMsg: 'Order not found',
	orderFailed: 'فشل تسجيل الطلب',
	orderCancelFailed: 'فشل الإلغاء الطلب',
	// Address
	newAddressAdded: 'تم إضافة العنوان',
	newAddressFailed: 'فشل إضافة العنوان',
	updateAddress: 'تم تعديل العنوان',
	updateAddressFailed: 'فشل تعديل العنوان',
	deleteAddress: 'تم حذف العنوان',
	deleteAddressFailed: 'فشل حذف العنوان',
	// Reviews
	reviewCreatedMsg: 'Review added',
	// Invalid
	invalidInput: 'Invalid input',
	notFoundMsg: 'Not found',
	errorMsg: 'Something went wrong. Please try again',
	// Loading
	loadingMsg: 'Loading...',
};
const paths = {
	// auth
	signupPath: '/signup',
	registerName: 'Register',
	loginPath: '/login',
	loginName: 'Login',
	logoutPath: '/logout',
	logoutName: 'Logout',

	// home
	indexPath: '/',
	indexName: 'Kitaab Store',
	homePath: '/home',
	homeName: 'Home',

	// books
	booksListPath: '/books-list',
	bookDetailsPath: '/book-details/',
	bookDetailsName: 'Details',
	storePath: '/store',
	storeName: 'Store',
	storeGoToName: 'Go to store',
	booksAdmin: '/books',
	addBookAdmin: '/add-book',
	// cart
	cartPath: '/cart',
	cartName: 'Cart',

	//checkout
	checkoutPath: '/checkout',

	// orders
	ordersPath: '/orders',

	// admin
	bookCreatePath: '/admin/create',
	bookCreateName: 'Create',
	bookEditPath: '/admin/edit',
	bookEditName: 'Edit book',
	bookDeletePath: '/admin/delete',
	bookDeleteName: 'Delete book',
	orderDetailsAdminPath: '/admin/orders/details',
	ordersAdminPath: '/admin/orders',

	// user
	profilePath: '/profile',
	profileDetailsPath: '/profile/',
	profileOrdersPath: '/profile/orders',
	profileAddressesPath: '/profile/addresses',
	//profileEditPath: '/profile/edit',
	//profileDeletePath: '/profile/delete',

	// Not Found
	notFoundPath: '*',
};

const actions = {
	create: 'create',
	edit: 'edit',
	delete: 'delete',
	login: 'login',
	register: 'register',
	verify: 'verify',
};

export {
	actions,
	auth,
	backAPI,
	//dbConstants,
	words,
	notifications,
	notificationMessages,
	paths,
};
