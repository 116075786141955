import { createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useAuth } from './AuthProvider';
import { useUserData } from './UserDataProvider';
import addressServices from '../services/addressServices';
import { notificationMessages } from '../constant/constants';

const AddressContext = createContext();

export function AddressProvider({ children }) {
	//const [loading, setLoading] = useState(false);
	//const [error, setError] = useState('false');
	const { dispatch } = useUserData();
	const [addressForm, setAddressForm] = useState({
		// _id: "",
		name: '',
		street: '',
		city: '',
		state: '',
		country: '',
		//pincode: '',
		phone: '',
	});
	const { auth } = useAuth();

	const [editAddressIndex, setEditAddressIndex] = useState([]);
	const [isEdit, setIsEdit] = useState(false);

	const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);

	const getAddressList = async () => {
		if (auth.isAuth) {
			try {
				//setLoading(true);
				const response = await addressServices.getAddressList();
				if (response.status === 200) {
					if (response.data.status === 'success') {
						let addresses = response.data.data;
						addresses = !!addresses ? addresses : [];
						//setLoading(false);
						dispatch({
							type: 'SET_ADDRESS',
							payload: addresses,
						});
					} else {
						toast.error(
							'addres not returned ' + response.data.message
						);
					}
				}
			} catch (error) {
				//setLoading(false);
			} finally {
				//setLoading(false);
			}
		}
	};

	const addAddressHandler = async () => {
		try {
			//setLoading(true);
			//setError('');
			const response = await addressServices.addAddress(addressForm);
			if (response.status === 201) {
				if (response.data.status === 'success') {
					let addresses = response.data.data;
					// addresses = !!addresses ? addresses : [];
					//setLoading(false);
					//toast.success(`${notificationMessages.newAddressAdded}!`);
					toast.success(response.data.message);
					dispatch({
						type: 'SET_ADDRESS',
						payload: addresses,
					});
				} else {
					toast.error(response.data.message);
				}
			} else {
				toast.error(response.data.message);
				//toast.error(`${notificationMessages.newAddressFailed}!`);
			}
		} catch (error) {
			//setLoading(false);
			//console.error(error);
			//toast.error(error.response.data.message);
			toast.error(`${notificationMessages.newAddressFailed}!`);
		} finally {
			//setLoading(false);
		}
	};

	const updateAddressHandler = async () => {
		try {
			//setLoading(true);
			//setError('');
			const response = await addressServices.updateAddress(addressForm);
			if (response.status === 200) {
				if (response.data.status === 'success') {
					let addresses = response.data.data;
					// addresses = !!addresses ? addresses : [];
					//setLoading(false);
					//toast.success(`${notificationMessages.newAddressAdded}!`);
					toast.success(response.data.message);
					dispatch({
						type: 'SET_ADDRESS',
						payload: addresses,
					});
				} else {
					toast.error(response.data.message);
				}
			} else {
				toast.error(`${notificationMessages.updateAddressFailed}!`);
			}
		} catch (error) {
			//setLoading(false);
			console.error(error);
			toast.error(`${notificationMessages.updateAddressFailed}!`);
		} finally {
			//setLoading(false);
		}
	};

	const deleteAddressHandler = async (address) => {
		try {
			//setLoading(true);
			//setError('');
			const response = await addressServices.removeAddress(address._id);
			if (response.status === 200) {
				if (response.data.status === 'success') {
					let addresses = response.data.data;
					addresses = addresses.length > 0 ? addresses : [];
					//setLoading(false);
					toast.success(
						`${notificationMessages.deleteAddress} ${address.name}!`
					);
					dispatch({
						type: 'SET_ADDRESS',
						payload: addresses,
					});
				} else {
					toast.error(response.data.message);
				}
			}
		} catch (error) {
			//setLoading(false);
			console.error(error);
			toast.error(`${notificationMessages.deleteAddressFailed}!`);
		}
	};

	useEffect(() => {
		if (auth.isAuth) {
			getAddressList();
		}
	}, [auth]);

	return (
		<AddressContext.Provider
			value={{
				editAddressIndex,
				setEditAddressIndex,
				addressForm,
				setAddressForm,
				isAddressModalOpen,
				setIsAddressModalOpen,
				isEdit,
				setIsEdit,
				addAddressHandler,
				updateAddressHandler,
				deleteAddressHandler,
			}}
		>
			{children}
		</AddressContext.Provider>
	);
}

export const useAddress = () => useContext(AddressContext);
