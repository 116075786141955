import './App.css';
import { Header } from './components/Header/Header';
import { Toaster } from 'react-hot-toast';
import { NavRoutes } from './routes/NavRoutes';
import { useData } from './contexts/DataProvider';
import { ScrollToTop } from './components/ScrollToTop/ScrollToTop';
import { Loader } from './components/Loader/Loader';
import { useUserData } from './contexts/UserDataProvider';
//import { Footer } from './components/Footer/Footer';
// import { Footer } from './components/Footer/Footer';

function App() {
	const { loading } = useData();
	const { cartLoading } = useUserData();

	return (
		<div className='App'>
			<Header />
			{(loading || cartLoading) && <Loader />}
			<NavRoutes />
			<ScrollToTop />
			<Toaster
				position='top-left'
				reverseOrder={false}
				toastOptions={{
					success: { duration: 3000 },
					error: { duration: 5000 },
				}}
				containerStyle={{
					top: '6rem',
				}}
			/>
			{/* <Footer /> */}
		</div>
	);
}

export default App;
