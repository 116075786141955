import React from 'react';
import { useAddress } from '../../../contexts/AddressProvider';
import { useUserData } from '../../../contexts/UserDataProvider';
//import { removeAddressService } from '../../../services/address-services/removeAddressService';
import './Addresses.css';
import { RiAddFill } from 'react-icons/ri';
//import { toast } from 'react-hot-toast';
import { AddressModal } from '../../Checkout/components/AddressModal/AddressModal';
import { words } from '../../../constant/constants';

const Addresses = () => {
	const { userDataState } = useUserData();
	const {
		setIsEdit,
		setAddressForm,
		isAddressModalOpen,
		setIsAddressModalOpen,
		deleteAddressHandler,
	} = useAddress();

	/*const deleteAddress = async (address) => {
		try {
			setLoading(true);
			setError('');
			const response = await removeAddressService(address, auth.token);
			if (response.status === 200) {
				toast.success(
					`${address.name}'s address successfully deleted!`
				);
				dispatch({
					type: 'SET_ADDRESS',
					payload: response.data.addresses,
				});
			} else {
				toast.error(response.data.message);
			}
		} catch (error) {
			setLoading(false);
			console.error(error);
		} finally {
			setLoading(false);
		}
	};*/

	const editButtonHandler = (address) => {
		setIsAddressModalOpen(true);
		setAddressForm(address);
		setIsEdit(true);
	};

	const addAddressHandler = () => {
		setIsAddressModalOpen(true);
	};
	return (
		<div className='address-section-container'>
			<div className='add-address-btn-container'>
				<button onClick={addAddressHandler}>
					{words.newAddress}
					<RiAddFill className='plus' />
				</button>
			</div>
			<div className='profile-address-container'>
				{userDataState.addressList.length ? (
					userDataState.addressList.map((address) => {
						const {
							name,
							street,
							city,
							state,
							country,
							phone,
							_id,
						} = address;
						return (
							<div className='address-card' key={_id}>
								<p className='name'>{name}</p>
								<p className='address'>
									<span>{words.fullAddress}:</span> {street},{' '}
									{city}, {state}, {country}
								</p>
								<p className='phone'>
									<span>{words.addressPhone}: </span>
									{phone}
								</p>
								<div className='address-btn-container'>
									<button
										onClick={() =>
											editButtonHandler(address)
										}
									>
										{words.addressEdit}
									</button>
									<button
										onClick={() =>
											deleteAddressHandler(address)
										}
									>
										{words.addressDelete}
									</button>
								</div>
							</div>
						);
					})
				) : (
					<p>{words.noAddress}</p>
				)}
			</div>

			{isAddressModalOpen && <AddressModal />}
		</div>
	);
};

export default Addresses;
