import React from 'react';
import { useUserData } from '../../../contexts/UserDataProvider';
import './Orders.css';
import { words } from '../../../constant/constants';
import { dateFormat } from '../../../helpers/formatData';
const Orders = () => {
	const { userDataState, cancelOrderHandler } = useUserData();
	const statusWord = (status) => {
		switch (status) {
			case 'pending':
				return words.orderPending;
			case 'accepted':
				return words.orderAccepted;
			case 'rejected':
				return words.orderRejected;
			case 'canceled':
				return words.orderCanceled;
			default:
				return status;
		}
	};

	return !userDataState.orders?.length ? (
		<div className='user-orders-container'>{words.noOrder}</div>
	) : (
		<div className='user-orders-container'>
			{userDataState.orders?.map(
				(
					{
						totalPrice: amountPaid,
						address: deliveryAddress,
						orderId,
						booksInfo: orderedProducts,
						status,
						creationDate,
						handleDate,
						//paymentId,
					},
					i
				) => (
					<div key={i} className='user-ordered-items-card'>
						<div className='user-order-id-container'>
							<span>{words.orderID}: </span>
							<span>{orderId}</span>
						</div>
						{/* <div className='user-payment-id-container'>
							<span>Payment ID: </span>
							<span>{paymentId}</span>
						</div> */}
						{/* <div className='user-price-container'>
							<span>{words.orderPaidAmount}: </span>
							<span>${amountPaid}</span>
						</div> */}
						<div className='user-price-container'>
							<span>{words.orderStatus}: </span>
							<span
								className={
									status === 'accepted'
										? 'main_text_color'
										: 'warning_text'
								}
							>
								{statusWord(status)}
							</span>
						</div>
						<div className='user-price-container'>
							<span>{words.orderCreationDate}: </span>
							<span>
								{`${dateFormat(new Date(creationDate))}`}
								{/* {`${timeFormat(
									new Date(creationDate)
								)} ${dateFormat(new Date(creationDate))}`} */}
							</span>
						</div>
						{handleDate && status !== 'pending' && (
							<div className='user-price-container'>
								<span>{words.orderHandleDate}: </span>
								<span>
									{`${dateFormat(new Date(handleDate))}`}
								</span>
							</div>
						)}
						<div className='user-price-container'>
							<span>{words.orderDeliveryAddress}:</span>
							<span>
								{deliveryAddress.country
									? `${deliveryAddress.country},`
									: ''}
								{deliveryAddress.state
									? `${deliveryAddress.state},`
									: ''}
								{deliveryAddress.city
									? `${deliveryAddress.city},`
									: ''}
								{deliveryAddress.street
									? `${deliveryAddress.street}`
									: ''}{' '}
								- {deliveryAddress.phone}
								{/* {deliveryAddress?.street}{' '}
								{deliveryAddress?.state}{' '}
								{deliveryAddress?.country} */}
							</span>
						</div>
						<div className='user-products-container'>
							{orderedProducts?.map(
								({
									bookId,
									imageUrl,
									title,
									price: discounted_price,
									qty = 1,
								}) => (
									<div
										className='user-products-card'
										key={bookId}
									>
										<img src={imageUrl} alt={title} />
										<div className='user-description'>
											<span className='user-name'>
												{/* {words.orderBookTitle}:*/}{' '}
												{title}
											</span>
											{/* <span className='user-qty'>
												{words.orderBookQty}: {qty}
											</span> */}
											{/* <span className='user-price'>
												{words.orderBookPrice}: $
												{discounted_price}
											</span> */}
										</div>
									</div>
								)
							)}
						</div>
						{status === 'pending' && (
							<div className='user-price-container'>
								<button
									className='cancel-button '
									onClick={() => cancelOrderHandler(orderId)}
								>
									{words.orderCancel}
								</button>
							</div>
						)}
					</div>
				)
			)}
		</div>
	);
};

export default Orders;
