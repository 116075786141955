import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthProvider';
import { useUserData } from '../../../contexts/UserDataProvider';
import './Logout.css';
import { paths, words } from '../../../constant/constants';

const Logout = () => {
	const { dispatch } = useUserData();
	const navigate = useNavigate();
	const { logoutHandler: authLogoutHandle } = useAuth();

	const logoutHandler = () => {
		authLogoutHandle();
		dispatch({
			type: 'SET_CART',
			payload: {
				books: [],
				totalPrice: 0,
			},
		});
		navigate(paths.indexPath);
	};
	return (
		<div className='logout-container '>
			<button onClick={logoutHandler} className='warning_bk'>
				{words.logout}
			</button>
		</div>
	);
};
export default Logout;
