import React from 'react';
import { useUserData } from '../../../../contexts/UserDataProvider';
import { Link } from 'react-router-dom';
import './CartAmountSummary.css';
import { paths, words } from '../../../../constant/constants';

export const CartAmountSummary = (props) => {
	const { userDataState, dispatch } = useUserData();
	const totalPrice = userDataState.cartTotalAmount;

	const placeOrderHandler = () => {
		dispatch({
			type: 'SET_ORDER',
			payload: {
				cartItemsTotal: totalPrice,
				cartItemsDiscountTotal: totalPrice, //totalDiscountedPriceAfterCoupon,
				couponDiscountTotal: totalPrice, //totalCouponDiscount,
				totalPrice: totalPrice,
				orderAddress: userDataState.addressList[0],
			},
		});
	};

	return (
		<div className='cart-summary'>
			<div className='cart-price-container '>
				<Link
					className='main_btn_bk'
					onClick={placeOrderHandler}
					to={paths.checkoutPath}
				>
					{words.placeOrder}
				</Link>
			</div>
		</div>
	);
};
