import React, { useEffect, useState } from 'react';
import App from './App';
//import { makeServer } from './server';
//import { BrowserRouter as Router } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { DataProvider } from './contexts/DataProvider';
import { AuthProvider } from './contexts/AuthProvider';
import { UserProvider } from './contexts/UserDataProvider';
import { AddressProvider } from './contexts/AddressProvider';
import { NoConnectionBack } from './pages/NoConnectionBack/NoConnectionBack';
import connectionService from './services/connectionService';
import { Loader } from './components/Loader/Loader0';
import toast from 'react-hot-toast';
const CLIENT_ID =
	'434310352973-sekfttap9ervpq840ct8gr2438sid0m0.apps.googleusercontent.com';

function wait(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

function App0() {
	const [checkConnection, setCheckConnection] = useState({
		check: true,
		status: false,
	});

	useEffect(() => {
		const checkConn = async () => {
			try {
				await wait(2000);
				const response = await connectionService.check();
				if (response.request.status === 200) {
					setCheckConnection({ check: false, status: true });
					return;
				}
				setCheckConnection({ check: false, status: false });
			} catch (error) {
				toast.error(JSON.stringify(error));
				setCheckConnection({ check: false, status: false });
			}
		};
		checkConn();
	}, []);

	if (checkConnection.check)
		return <Loader loading={checkConnection.check} />;
	else if (!checkConnection.status) return <NoConnectionBack />;
	else
		return (
			//<React.StrictMode>
			<GoogleOAuthProvider clientId={CLIENT_ID}>
				<AuthProvider>
					<DataProvider>
						<UserProvider>
							<AddressProvider>
								<App />
							</AddressProvider>
						</UserProvider>
					</DataProvider>
				</AuthProvider>
			</GoogleOAuthProvider>
			//</React.StrictMode>
		);
}
export default App0;
