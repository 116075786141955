import './NoConnectionBack.css';
import React from 'react';
//import { useNavigate } from 'react-router-dom';
import { words } from '../../constant/constants';
import { Footer } from '../../components/Footer/Footer';

export const NoConnectionBack = () => {
	//const navigate = useNavigate();
	return (
		<div className='noconn-items-container'>
			{/* <h2 className='page-heading'>Error 404</h2> */}
			<h2>{words.noConnectToBack}</h2>
			{/* <button
				onClick={() => navigate(paths.indexPath)}
				className='explore-btn'
			>
				{words.backHome}
			</button> */}
			<div className='noconn-footer'>
				<Footer />
			</div>
		</div>
	);
};
